import React, { FC } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  locale: string;
  path: string;
  onlyLanguages?: string[]; // list of available languages, defined at MarkdownPage
}

const LangSwitcher: FC<IProps> = ({ locale, path = '/', onlyLanguages }) => {
  const newLocales = [
    { lang: 'ru', path: '/' },
    { lang: 'en', path: 'https://icerockdev.com?language=en' },
  ];

  return (
    <div className={styles.langs}>
      <a href={newLocales[1].path}>{newLocales[1].lang}</a>
      <span
        key={newLocales[0].lang}
        className={classnames({
          [styles.is_active]: locale === newLocales[0].lang,
        })}
      >
        {newLocales[0].lang}
      </span>
    </div>
  );
};

export { LangSwitcher };
