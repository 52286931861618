/* eslint-disable import/extensions */
import React, { FC, ReactNode } from 'react';
import {
  injectIntl,
  IntlProvider,
  IntlShape,
  ReactIntlErrorCode,
} from 'react-intl';
import flatten from 'flat';
import Typograf from 'typograf';
import chalk from 'chalk';
import locales from '../constants/locales';

import ru from './locales/ru.js';
import 'date-fns/locale/ru';

chalk.enabled = true;
chalk.level = 3;

const tp = new Typograf({ locale: ['ru'] });

const messages = {
  ru: flatten(ru) as Record<string, string>,
};

export type Locale = keyof typeof messages;
export const DEFAULT_LOCALE =
  Object.keys(locales).find((locale) => locales[locale].default) || 'ru';

type IProps = ReactNode & {
  locale: string;
};

const Trans: FC<IProps> = ({ locale, children }) => (
  <IntlProvider
    locale={locale}
    messages={messages[locale as Locale]}
    onError={(e) => {
      if (e.code === ReactIntlErrorCode.MISSING_DATA) {
        return;
      }
      if (e.code === ReactIntlErrorCode.FORMAT_ERROR) {
        // eslint-disable-next-line no-console
        console.log(
          `${chalk.yellow('warn')} FORMAT_ERROR in ${e?.descriptor?.id}`
        );
        return;
      }
      // eslint-disable-next-line no-console
      console.warn(e);
    }}
  >
    {children}
  </IntlProvider>
);

// const t: FC<string> = id => <FormattedMessage id={id} />

const trans: FC<{ id: string; intl: IntlShape }> = ({ id, intl }) => {
  const placeholder = intl.formatMessage({ id });

  return <span dangerouslySetInnerHTML={{ __html: tp.execute(placeholder) }} />;
};

const t: FC<string> = (id: string) => injectIntl(trans)({ id });

export { Trans, t };
