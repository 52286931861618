export default {
  menu: {
    about: 'О нас',
    projects: 'Проекты',
    blogs: 'Блог',
    company: 'Компания',
    team: 'Команда',
    vacancy: 'Вакансии',
    contacts: 'Контакты',
    career: 'Карьера',
    directions: 'Направления',
    portfolio: 'Кейсы',
    reviews: 'Отзывы',
    blog_kotlin: 'Kotlin Multiplatform',
    blog_mobile: 'Mobile Development',
    blog_uiux: 'UX / UI Design',
    blog_company: 'Company Life',
    order_app: 'Заказать приложение',
    back: 'Назад',
  },
  directions: {
    title:
      'За годы работы мы накопили экспертизу в разных направлениях бизнеса',
    detail_information: 'Подробнее',
    count: '{count, plural, =zero {нет кейсов} one {# кейс} other {# кейсов}}',
  },
  main: {
    not_found: 'Страница не найдена',
    page_missing: 'Упс, кажется, эта страница пропала',
    back_to_home: 'Вернуться на главную',
    back: 'назад',
  },
  title: {
    main: 'Главная',
    not_found: 'Не найдено',
  },
  blog: {
    show_more: 'Показать больше',
    show_previous_posts: 'Предыдущие посты',
    subscribe_text: 'Подписаться на обновления блога',
    subscribe: 'Подписаться',
    success: 'Вы успешно подписались',
    tags: {
      'kotlin-multiplatform': 'Kotlin Multiplatform',
      mobile: 'Mobile Development',
      'ui-ux': 'UX / UI Design',
      'company-life': 'Company Life',
    },
  },
  portfolio: {
    show_more: 'Показать больше',
    show_previous_projects: 'Предыдущие проекты',
  },
  about: {
    title: 'Мы — Kotlin Multiplatform эксперты',
    top_projects: 'Главные проекты',
    link: 'ПОКАЗАТЬ ВСЕ',
    projects: 'Проекты',
    awards: 'Награды',
    top_directions: 'Главные направления',
    directions: 'Направления',
    expertise: {
      title: 'Экспертиза',
      description_1: {
        text: 'Мы являемся экспертами в разработке мобильных приложений на',
        link_text: 'Kotlin Multiplatform',
      },
      description_2: {
        text: ' - технология, позволяющая создавать нативные мобильные приложения с единой бизнес логикой, что позволяет повысить надежность приложения и сократить сроки разработки',
        link_text: 'Kotlin Multiplatform',
      },
    },
    ratings: 'Рейтинги',
    we_in_media: 'Мы в медиа',
    reviews: 'Отзывы',
  },
  vacancies: {
    title: 'Нам нужны',
    success_title: 'Спасибо за обращение!',
    success_text: 'Мы с вами обязательно свяжемся',
    detail_information: 'Детальная информация',
    we_need: 'Вакансии',
    ok: 'OK',
    freeform: {
      title: 'Сейчас мы не ищем новых сотрудников',
      description: 'Вы можете предложить свою кандидатуру, и мы напишем вам',
      tell: 'Расскажите о себе',
      send_request: 'Отправить',
    },
    page: {
      title: 'Отправить заявку',
      send_request: 'Отправить',
    },
  },
  cases: {
    blog: {
      email: 'Email*',
    },
    delivery: {
      get_advice: 'Получить консультацию',
      find_more: 'Узнать подробности',
      title: {
        text: 'Продавайте онлайн и сохраните клиентов',
        text_condensed: 'c помощью мобильного приложения по заказу и доставке',
        note: 'Разработка от 3-х дней. Коробочное решение в 3 раза дешевле индивидуального',
      },
      reasons: {
        title: 'Как моему бизнесу поможет приложение?',
        card_1: 'Для ресторанов',
        card_2: 'Для аптек',
        card_3: 'Для магазинов',
        reason_1__title: 'Безопасность',
        reason_1:
          'Создаст безопасную альтернативу оффлайн-покупкам во время карантина',
        reason_2__title: 'Доставка',
        reason_2:
          'Поможет наладить доставку — есть интеграция с Доставистой или другими местными сервисами',
        reason_3__title: 'Поиск клиентов',
        reason_3: 'Сохранит клиентов и поможет найти новых',
        reason_4__title: 'Лояльность',
        reason_4:
          'Повысит лояльность клиентов — им удобно делать заказ, они видят, что в приложении есть контент, который интересно смотреть и читать',
        reason_5__title: 'Увеличение среднего чека',
        reason_5:
          'Увеличит средний чек и кол-во покупок за счёт индивидуальных подборок товаров и информирования об акциях, скидках, предложениях\n',
      },
      graphs: {
        title: 'Почему важно сделать приложение именно сейчас?',
        description:
          'После снятия карантина поведение покупателей изменится. Люди поймут, что доставка &mdash, удобный сервис. Они привыкнут делать заказы онлайн. К тому времени многие компании уже сделают мобильные приложения и организуют доставку. Без этих сервисов сложно будет приспособиться к новым условиям и конкурировать за клиентов. А создавать и раскручивать их с нуля будет поздно.',
        graph_1_alt: 'Рынок доставки продуктов',
        graph_2_alt: '',
      },
      interaction: {
        title: 'Мы сделаем простое и понятное приложение',
        card_1_title: 'Уведомления',
        card_1:
          'Они быстро получат уведомление о новинках. Ассортимент в приложении автоматически обновляется из вашей CRM',
        card_2_title: 'Интересный контент',
        card_2:
          'В приложение можно добавить вовлекающий контент: видео-рецепты, обзоры, отзывы, видео с кухни',
        card_3_title: 'Клиентская база',
        card_3:
          'Можно загрузить базу адресов. Вы будете информировать покупателей о скидках, акциях, индивидуальных предложениях',
        card_4_title: 'Бонусная программа',
        card_4:
          'Приложение позволит запустить программы лояльности: ввести баллы, промокоды',
      },
      app: {
        title: 'Мы сделаем простое и понятное приложение',
        description:
          'Им удобно пользоваться клиентам и вашим сотрудникам. В приложении есть три интерфейса:',
        for_courier: 'Для курьера',
        for_constructor: 'Для сборщика',
        for_client: 'Для клиента',
      },
      how: {
        title: 'Как это работает?',
        description:
          'У пользователя в системе может быть 3 роли. Каждая роль может быть монетизирована, являться частью единой инфраструктуры или быть отдельным независимым подразделением или партнером',
        customer: 'Customer',
        platform: 'Platform',
        shop: 'Shop',
        storage: 'Storage',
        courier: 'Courier',
        goods_selection: 'Поиск товара',
        order_processing: 'Оформление заказа и оплата',
        packaging: 'Сборка',
        delivery: 'Доставка',
      },
      where: {
        title: 'Где работает наше приложение?',
        p1: 'Мы создаем приложения для IOS и Android',
        p2: 'Поддерживаем мультиплатформенную разработку на языке Kotlin.',
        p3: 'Он позволяет написать приложение на одном языке сразу под 8 платформ',
      },
      risks: {
        title: 'Мы предусмотрели все риски',
        card1:
          'У нас большой опыт разработки и готовые платформы. Мы в короткие сроки создаем приложение и настраиваем его под конкретный бизнес',
        card2:
          'Приложение быстро выпускаем на рынок &mdash, всего за 3 рабочих дня',
        card3:
          'Наши приложения работают в рамках законодательства. Мы учитываем все юридические нюансы, поэтому можно не беспокоиться о проблемах с налоговой службой и другими инстанциями',
      },
      about: {
        title: 'Кто мы?',
        subtitle:
          'Мы небольшая команда специалистов из самого сердца Сибири, её технического центра &mdash, Новосибирска. Специализируемся на разработке мобильных приложений для Android и IOS. Наши знания и опыт позволяют нам эффективно решать задачи и создавать качественные, удобные и эффективные приложения',
        pogrebnyak: 'Александр Погребняк',
        director: 'Руководитель',
        getmanov: 'Валерий Гетманов',
        csm: 'Менеджер по работе с клиентами',
        mikhailov: 'Алексей Михайлов',
        techdir: 'Технический директор',
        grebenschikov: 'Евгений Гребенщиков',
        artdir: 'Арт-директор',
        tchernov: 'Андрей Чернов',
        ios: 'Руководитель iOS-отдела',
        shvedov: 'Алексей Шведов',
        web: 'Руководитель Web-отдела',
      },
      launch: {
        title: 'У нас понятный и прозрачный цикл запуска',
        subtitle: 'Разработка приложения занимает от 3-х дней',
        install: 'Устанавливаем приложение',
        launch: 'Запускаем',
        solution: 'Предлагаем комплексное решение по маркетингу',
        mailout: 'Cоздаем рассылку (серия писем по базе клиентов)',
        landing: 'Делаем лендинг',
        ads: 'Разработываем рекламные кампаний в Yandex и Google',
      },
      showcase: {
        title: 'Мы уже разработали приложения для',
        case_1_title: 'PrimeTime',
        case_1_info: 'Сервис заказа домашней еды',
        case_1_url: 'https://primetimecoffee.ru/',
        case_2_title: 'ServedIn',
        case_2_info: 'Приложение позволяет заказывать блюда в апартаменты',
        case_2_url: ' ',
        case_3_title: 'Доставка',
        case_3_info: 'Сервис моментальной доставки продуктов',
        case_3_url: ' ',
        launch_soon: 'Скоро запуск',
      },
      solution: {
        title:
          'Мы предлагаем &laquo;коробочное&raquo; решение в собственность или по подписке',
        saas: {
          title: 'SAAS',
          p1: 'Мобильное приложение с фирменным стилем',
          p2: 'Данные и сервер находятся у разработчика',
          p3: 'Разработка от 3-х дней',
          p4: 'Подключение 200 т.руб, ежемесячно 55 т.руб',
        },
        box: {
          title: '&laquo;Коробочное решение&raquo;',
          p1: 'Создаем фирменное мобильное приложение',
          p2: 'Сервер и все данные принадлежат вам',
          p3: 'Можете подключить любой сервис или интеграции',
          p4: 'Разработка от 2-х недель, стоимость: 1-1,5 млн. руб',
        },
        custom: {
          title: 'Индивидуальная разработка',
          p1: 'Разрабатываем с &laquo;нуля&raquo; именно под ваш бренд',
          p2: 'Вы владеете сервером и данными',
          p3: 'Можете подключить любой сервис или интеграции',
          p4: 'Разработка от 4-х месяцев, стоимость от 3 млн.руб',
        },
      },
      contacts: {
        title: 'Заявка на консультацию',
        subtitle: 'Наш менеджер свяжется с вами в ближайшее время',
        name: 'Имя*',
        phone: 'Телефон*',
        comment: 'Комментарий',
        send: 'Отправить',
        required: 'Пожалуйста, заполните это поле',
        thanks: 'Спасибо за обращение!',
        fileName: 'Добавить документ',
        thanks_description: 'Наш менеджер свяжется с вами в ближайшее время',
      },
    },
    design: {
      modalTitle: 'Заявка на дизайн',
      blog: {
        title: 'Блог',
      },
      portfolio: {
        title: 'Примеры работ',
      },
      reviews: {
        title: 'Отзывы о нашей работе',
      },
      form: {
        title: 'Расскажите о вашем проекте',
        button: 'Отправить',
        checkbox_text_start: 'Согласие на',
        checkbox_text_end: 'обработку персональных данных',
        checkbox_error: 'Примите согласие на обработку персональных данных',
        name: 'Ваше имя*',
        phone: 'Телефон*',
        email: 'Email',
        message: 'Сообщение',
        file: 'Прикрепить файл',
      },
      footer: {
        phone: '+74951097329',
        email: 'mobiledev@icerockdev.com',
      },
      title: {
        phone: '+74951097329',
        email: 'mobiledev@icerockdev.com',
        title:
          'Современный дизайн приложения, который адаптирован для мобильной разработки',
        desc: 'IceRock — студия разработки мобильных приложений. У нас два главных подразделения: отдел разработки и отдел дизайна. Оба отдела тесно общаются между собой, поэтому наши дизайнеры делают не только красивый визуал, но и понимают, как программисты будут превращать его в код. В результате вы получите мобильное приложение, которое отлично и выглядит, и работает.',
        button: 'Оставить заявку',
      },
      reasons: {
        reasons_1: {
          desc: '6 лет опыта мобильной разработки, 60+ приложений',
        },
        reasons_2: {
          title: 'Полноценно',
          desc: 'Имеем собственный штат тестировщиков',
        },
        reasons_3: {
          title: 'Вариативно',
          desc: 'Исследования на фокус-группах реальных пользователей',
        },
      },
      promises: {
        promise_0: {
          title: { colored: 'Сформируем', rest: 'общее видение проекта' },
          paragraph_0: {
            textStart:
              'Перед началом работы мы подготовим документ (вижн), в котором опишем все детали проекта: цели, задачи, ключевые функции и первые наброски дизайна. Вижн формируется итерационно: мы показываем свое понимание задачи, а вы вносите правки, если в первой версии есть неточности.',
          },
          paragraph_1: {
            textStart:
              'Мы приступаем к работе только после того, как согласуем документ. Это нужно, чтобы сформировать единое видение проекта с самого начала и свести количество переделок к минимуму.',
          },
        },
        promise_1: {
          title: { colored: 'Поможем,', rest: 'когда есть только идея' },
          paragraph_0: {
            textStart:
              'Вместе с вами подготовим техническое задание и прототип будущего дизайна. Проведем анализ конкурентов, предложим референсы актуальных трендов и подготовим несколько дизайн-концепций. Выберем ту, что больше понравится и отрисуем.',
          },
        },
        promise_2: {
          title: {
            colored: 'Работаем',
            rest: 'быстрее, чем многие дизайн-студии',
          },
          paragraph_0: {
            textStart:
              'За 6 лет работы мы реализовали больше 60 проектов. За это время разработали обширную дизайн-систему, которую можно адаптировать под разные задачи. Это значит, что мы не делаем каждый раз дизайн с чистого листа, а используем наработки, которые уже помогали решать похожие проблемы.',
          },
          paragraph_1: {
            textStart:
              'Иногда дизайн или даже прототип нужны срочно, например к презентации для инвестора. Если вы придете к нам с задачей за',
            textBold: '2 недели',
            textEnd:
              'до этой даты, мы успеем создать визуальное решение для вашего продукта.',
          },
        },
        promise_3: {
          title: {
            colored: 'Подготовим',
            rest: 'дизайн, который легко преобразовать в код',
          },
          paragraph_0: {
            textStart:
              'Некоторые дизайнеры не понимают, как их работа оживет в коде. Они отпускают фантазию и рисуют такой интерфейс, который сложно, дорого либо невозможно реализовать. Из-за этого разработчикам приходится упрощать интерфейс либо переделывать работу полностью.',
          },
          paragraph_1: {
            textStart:
              'Наш дизайн готов к использованию любыми разработчиками. Дизайнеры работают в тесной сцепке с программистами и делают работу так, что ее легко преобразовать в код.',
          },
        },
        promise_4: {
          title: {
            colored: 'Проведем',
            rest: 'исследование в фокус-группах и тестирование на ошибки',
          },
          paragraph_0: {
            textStart:
              'Чтобы приложение нравилось пользователям, мы проводим подготовительную работу: исследование в фокус-группах и тестирование среди целевой аудитории.',
          },
          paragraph_1: {
            textStart:
              'Если у вас есть потенциальные пользователи, мы разработаем методику тестирования, проведем тест и проанализируем результаты. Пожелания и комментарии пользователей учтем в готовом продукте.',
          },
          paragraph_2: {
            textStart:
              'Чтобы выявить ошибки, мы тестируем приложения во всех возможных сценариях. Этим занимаются наши штатные тестировщики.',
          },
        },
        promise_5: {
          title: { colored: 'Разработаем', rest: 'интерактивный прототип' },
          paragraph_0: {
            textStart: 'Кроме дизайна у нас можно заказать',
            textLink: 'рабочий прототип.',
            textEnd:
              'Это продвинутый уровень. \n' +
              'На прототипе можно тестировать идеи, экспериментировать с дизайном, искать ошибки в логике еще до разработки. А еще с ним проще показать, как все работает.',
          },
        },
      },
      sale: {
        title: 'Первый экран дизайна - бесплатно!',
        desc: 'С момента возникновения идеи создания проекта до момента его использования пользователем, продукт проходит несколько этапов разработки.',
      },
      process: {
        title: 'Процесс разработки',
        step_1: {
          number: '01',
          title: 'Разработка дизайн-концепта',
          desc: 'Предлагаем дизайн основных экранов, согласовываем с вами. Может протекать параллельно с разработкой UX-прототипа.',
        },
        step_2: {
          number: '02',
          title: 'Анализ требований, разработка ТЗ',
          desc: 'Вы описываете нам вашу идею, мы ее обсуждаем, разделяем на этапы, согласовываем роадмап.',
        },
        step_3: {
          number: '03',
          title: 'Разработка UX-прототипа',
          desc: 'Присылаем вам прототип, который показывает полный функционал оговоренный на первом этапе.',
        },
        step_4: {
          number: '04',
          title: 'Разработка дизайна',
          desc: 'После того, как UX-прототип и концепт утверждены, начинается разработка полноценного дизайна всего приложения.',
        },
        step_5: {
          number: '05',
          title: 'Подготовка дизайна для разработчика',
          desc: 'Платформы имеют уникальные требования. Мы экономим время разработчиков, правильно подготавливая материалы.',
        },
        step_6: {
          number: '06',
          title: 'Тестирование / презентация продукта',
          desc: 'Подготовка продукта перед тестированием на фокус группе или презентацией для инвесторов.',
        },
      },
      benefits: {
        title: 'Программисты точно скажут нам спасибо!',
        desc: 'Серьезно подходим к подготовке дизайна для передачи в разработку, что способствует быстрому внедрению программистов в проект и экономии времени на разработку.',
        benefits_1: {
          title: 'Экономим время разработки',
          desc: 'Правильно подгатавливая исходные материалы проекта, мы экономим до 50% времени на разработку.',
        },
        benefits_2: {
          title: 'Упрощяем погружение в проект',
          desc: 'Правильно подгатавливая исходные материалы проекта, мы экономим нервы и мотивацию разработчика.',
        },
        benefits_3: {
          title: 'Скидка 20%',
          desc: 'При заказе услуг наших программистов вы гарантировано получаете скидку 20% на разработку.',
        },
      },
      faq: {
        title: 'Частые вопросы',
        questions: {
          question_1: 'Что необходимо для старта дизайна?',
          answer_1:
            'Вы можете прийти к нам, как со сформированным вижном проекта и описанием бизнес-процессов, так и с “голой” идеей, которую мы проапгрейдим в процессе первоначальных обсуждений проекта, учитывая ваши пожелания, наши профессиональные знания и последние тренды рынка.',
          question_2: 'Как формируется стоимость дизайна?',
          answer_2: 'Binance Spot и Binance Futures.',
          question_3: 'Сколько вариантов дизайна мы делаем?',
          answer_3:
            'Да, если у неё есть взаимодействие через программный интерфейс (API) и все необходимые методы. Напишите нам, какие биржи вам интересны и мы подскажем какие можно подключить',
        },
      },
    },
    restocare: {
      title: {
        iiko: 'IIKO',
        title:
          'Собственное мобильное приложение с доставкой и самовывозом для ресторана',
        fast: 'Интеграция с iiko ',
        free_launch: 'Бесплатный запуск',
        ios_android: 'Для Apple и Android',
      },
      cards: {
        title: 'Новый сервис для ваших гостей',
        subtitle: 'Ещё больше клиентов для ресторана',
        card_title_1: 'Удобная доставка и самовывоз',
        card_text_1:
          'Теперь ваши гости смогут заказать свои любимые блюда домой или забрать проездом, быстро и безопасно.',
        card_title_2:
          'Интеграция с системой управления рестораном iiko включена в продукт',
        card_text_2:
          'Мгновенное получение заказа на кассу, авто-оповещение курьера. Вы успеете собрать заказ и не потеряете ни секунды.',
        card_title_3: 'Отслеживание заказа на карте',
        card_text_3:
          'Отслеживайте курьера на карте, а также его задержки и опоздания, рассчитывайте среднее время доставки.',
      },
      launch: {
        title: 'Запустим ваше приложение в кратчайшие сроки',
        term_1:
          'Срок запуска 1 неделя + регистрация в AppStore для приложения под iOS',
        term_2:
          'Единственный платеж при запуске &mdash, $99 за регистрацию в AppStore и $25 за регистрацию в GooglePlay',
        term_3: 'Вы платите 5% от стоимости каждого заказа плюс эквайринг',
      },
      contact: {
        title: 'Хочу такое приложение',
        your_name: 'Ваше имя*',
        company_name: 'Компания*',
        email: 'Email',
        send: 'Отправить',
        terms: 'Нажимая кнопку ОТПРАВИТЬ, вы даете согласие на обработку своих',
        personal_data: 'персональных данных',
        fill_this_field: 'Заполните это поле',
        success_title: 'Спасибо за обращение!',
        success_subtitle: 'Наш менеджер свяжется с вами в ближайшее время',
      },
      copyright: '&copy, 2021 Компания IceRock Development',
    },
    crypto: {
      header: {
        crypto: 'Торговые боты',
        title: 'Разработка торговых ботов под ключ | IceRock',
        desc: 'Зарабатывайте больше, автоматизировав любую торговую стратегию',
        button: 'Подробнее',
      },
      cards: {
        card_title_1: 'Лёгкий старт',
        card_text_1: 'Всего несколько простых вопросов для старта бота',
        card_title_2: 'Полная автоматизация',
        card_text_2:
          'Возможность создать и запустить алгоритм, который будет работать 24х7',
        card_title_3: 'Детальная статистика',
        card_text_3:
          'Веб-сайт с личным кабинетом, телеграм-бот – любой вариант взаимодействия с ботом',
        card_title_4: 'Гибкая кастомизация',
        card_text_4: 'Внесём изменения в алгоритм, отображение, интеграцию',
      },
      steps: {
        title: 'Как это работает?',
        step_1: {
          number: '01',
          title: 'Выберите оболочку для управления ботом',
          desc: 'Это может быть телеграм-бот или собственный сайт',
        },
        step_2: {
          number: '02',
          title: 'Определите торговый алгоритм',
          desc: 'Можем реализовать любой ваш алгоритм или использовать наш',
        },
        step_3: {
          number: '03',
          title: 'Выберите биржу для торговли бота',
          desc: 'Binance, Bybit и другие биржи, где есть возможность работать через API',
        },
        step_4: {
          number: '04',
          title: 'Мы это всё собираем в один продукт',
          desc: 'Тут не обойдется без программистов и тестировщиков',
        },
        step_5: {
          number: '05',
          title: 'Вы пассивно зарабатываете деньги',
          desc: 'Но мы не можем гарантировать доходность из-за рыночных рисков',
        },
      },
      realizations: {
        realizations_1: {
          number: 'Реализация #1',
          title: 'Grid-бот',
          desc: 'Сеточная стратегия работает с отложенными лимитными ордерами (limit orders) на покупку и продажу по заранее определенным цена. Выбираемый пользователем бота ценовой диапазон разбивается на задаваемое им же число уровней (grids) и создает таким образом так называемую сетку из ордеров. Именно поэтому данный метод называется Сеточная стратегия или Grid Strategy. Прибыль достигается за счет продажи-покупки определенного количества по мере движения курса',
        },
        realizations_2: {
          number: 'Реализация #2',
          title: 'Метод Мартингейла',
          desc: 'Мартингейл - это стратегия усреднения затрат. Это происходит путем «удвоения риска» при убыточных сделках. Это приводит к снижению вашей средней цены входа. Прибыль достигается при развороте движения рынка',
        },
      },
      contact: {
        title: 'Хочу такое приложение',
        your_name: 'Ваше имя*',
        company_name: 'Компания*',
        email: 'Email',
        send: 'Отправить',
        terms:
          'Нажимая кнопку «ОТПРАВИТЬ», вы даете согласие на обработку своих персональных данных',
        personal_data: 'персональных данных',
        fill_this_field: 'Заполните это поле',
        success_title: 'Спасибо за обращение!',
        success_subtitle: 'Наш менеджер свяжется с вами в ближайшее время',
      },
      faq: {
        faq: 'FAQ',
        title: 'Частые вопросы',
        questions: {
          question_1: 'Как подключается биржа?',
          answer_1:
            'Для подключения биржи необходимо, чтобы биржа поддерживала работу через API, после чего наши программисты смогут написать программный код для работы по стандартным операциям (покупка, продажа актива, чтение баланса).',
          question_2: 'Какие биржи уже подключены?',
          answer_2: 'Binance Spot и Binance Futures.',
          question_3: 'А можно ли подключить другую биржу?',
          answer_3:
            'Да, если у неё есть взаимодействие через программный интерфейс (API) и все необходимые методы. Напишите нам, какие биржи вам интересны и мы подскажем какие можно подключить',
          question_4: 'Возможно ли реализовать сторонние интеграции?',
          answer_4:
            'Да, так например, мы реализовали интеграцию с Matlab и можем запустить бота с любым алгоритмом на этом программном инструменте.',
          question_5: 'Какие допустимые риски у бота?',
          answer_5:
            'Риски каждый из заказчиков закладывает самостоятельно, это очень важный момент и необходимо долгое время тестирования и отладки алгоритма (в том числе на тестовой среде и по историческим данным), чтобы запускать бота на реальном рынке.',
        },
      },
    },
    kmm: {
      header: {
        name: 'Митап по Kotlin Multiplatform Mobile (KMM)',
        title:
          'Задавайте вопросы по вашим кейсам и решайте задачи на KMM вместе с IceRock',
        desc: '28 сентября в 14:00 по Мск iOS-разработчик Андрей покажет как за 30 минут с помощью КММ можно сделать из пустого шаблона фичи базовый экран авторизации, с валидациями полей и обработкой типовых ошибок. А Android-разработчик Костя после этого сможет за 15 минут использовать эту логику у себя и получить такой же экран авторизации',
        button: 'Узнать подробнее',
        modalTitle: 'Запись на meetup',
      },
      whyKotlin: {
        title: 'Почему митапы с  <br/> <span>IceRock</span> — это круто',
        desc_1:
          'Мы, IceRock, — эксперты в разработке приложений на Kotlin Multiplatform Mobile. За 3,5 года работы с технологией наша команда создала несколько десятков приложений под разные потребности клиентов.',
        desc_2:
          'С 2019 года наши специалисты выступают на профильных конференциях вроде KotlinConf и APAC, а еще разрабатывают <a href="https://moko.icerock.dev/" target="_blank">мультиплатформенные библиотеки</a> на Kotlin.',
        desc_3:
          'Практика в решении задач на KMM сделала нас лучшими в консалтинге и разработке — как в России, так и за рубежом. А еще у IceRock есть блоги на  <a href="https://habr.com/ru/users/icerockdev/posts/" target="_blank">«Хабре»</a> и  <a href="https://vc.ru/u/208866-icerock" target="_blank">vc.ru</a>, где мы тоже делимся опытом, в том числе показываем наши кейсы.',
        desc_4:
          'KMM помогает нам решать самые разные задачи, а мы помогаем сообществу программистов познакомиться с этим инструментом и оценить его преимущества на наших кейсах. Митап — один из способов делать это максимально эффективно.',
      },
      howIsItDone: {
        title: 'Как проходит митап',
        fitItem_1: { title: 'Где?', description: 'В Google meet' },
        fitItem_2: {
          title: 'Когда?',
          description: 'Каждые две недели в среду в 14:00 мск',
        },
        fitItem_3: { title: 'Сколько?', description: '1 час' },
        fitItem_4: {
          title: 'С кем?',
          description: 'Эксперт(ы) IceRock и группа из трех-четырех человек',
        },
        fitItem_5: { title: 'Цена', description: 'Бесплатно' },

        graduates: 'Выпускникам факультетов мобильной разработки',
        develiopers:
          'Действующим Junior и Middle программистам на iOS и Android',
        manager: 'Product-менеджерам с навыками программирования и Senior’ам',
      },
      whoShouldGo: {
        title: 'Кому стоит прийти',
        paragraph_1:
          'Всем программистам, которые работают с Kotlin Multiplatform Mobile или только интересуются этой технологией.',
        paragraph_2:
          'Участники наших встреч не только задают вопросы экспертам IceRock, но и вместе ищут варианты решения задач, которые появляются в ходе реальной работы. Поэтому митапы IceRock — это настоящие площадки по обмену опытом.',
        inviteTitle: 'Мы ждем специалистов всех уровней:',
        guestType_1: 'Junior (в KMM)',
        guestType_2: 'Middle',
        guestType_3: 'Senior',
        beginnerTitle: 'Для тех, кто только начинает',
        beginnerParagraph_1:
          'А если вы Junior и мало знакомы с KMM, перед посещением митапа вам могут пригодиться наши стажировки. Там вы погрузитесь в процесс и получите реальные задачи, которые потом захочется обсудить.',
        beginnerParagraph_2:
          'Это тот опыт, который поможет разобраться и двигаться дальше — к новым, еще более интересным проектам!',
        beginnerParagraph_3: 'Стажировка — это:',
        beginnerLi_1: 'Бесплатная практика в крупных проектах',
        beginnerLi_2: '4-8 недель на прокачку полезного навыка',
        beginnerLi_3: 'Задания с повышением уровня сложности и фидбэком',
        beginnerButton: 'Записаться на стажировку',
        beginnerModalTitle: 'Запись на стажировку',
        beginnerSubTitle:
          'Чтобы попасть на стажировку, напишите нам на <a href="mailto:training@icerockdev.com,  qa@icerockdev.com">training@icerockdev.com</a> письмо с темой «Хочу на стажировку!»',
      },
      aboutUs: {
        title:
          'Мы, IceRock, — эксперты в разработке приложений на Kotlin Multiplatform Mobile',
        desc_1:
          'С 2019 года делимся опытом на конференциях вроде KotlinConf и APAC и разрабатываем мультиплатформенные библиотеки на Kotlin.',
        desc_2:
          'Наши клиенты — Leroy Merlin, VEKA, Delivery Club, Marmalato, Золотое Яблоко и другие.',
        button: 'Посмотреть наши кейсы',
      },
      whenToGo: {
        title: 'Когда пора  на MeetUp',
        subtitle:
          'Основные — но не все! — вопросы, в которых можно разобраться на наших встречах:',
        reason_1: 'Не понимаете, с чего начать работать с KMM',
        reason_2: 'Уже начали внедрение, и нужна помощь в расширении KMM',
        reason_3:
          'Видите пользу технологии в работе и хотите извлечь из нее максимум',
        reason_4: 'Оценили KMM, но не получилось донести ценность до команды',
        notice: {
          title: 'Важно!',
          description:
            'Ценность митапов в том, что это не онлайн-лекция — даже на встрече с заданной темой всегда можно обсудить свои вопросы и подискутировать.',
        },
      },
      faq: {
        title: 'FAQ',
        question_1: 'Сколько стоит участие в митапе?',
        answer_1: 'Участие бесплатное.',
        question_2: 'Есть ли критерии отбора участников?',
        answer_2: 'Принять участие в митапе могут все желающие.',
        question_3: 'Сколько человек может участвовать?',
        answer_3:
          'Мы организуем встречи небольшими группами, три-четыре человека.',
        question_4: 'Можно ли прийти группой от компании?',
        answer_4:
          'Да, митап можно провести для специалистов из одной организации.',
        question_5: 'Нужно посещать митапы постоянно или достаточно одного?',
        answer_5:
          'Митап — это как мастер-класс или семинар. На встречах мы рассматриваем конкретные вопросы, кейсы и ситуации. Если есть вопросы или очень интересны темы, приходите сколько угодно. Нашли ответ на одной встрече — тоже супер.',
        question_6: 'У митапа всегда есть тема?',
        answer_6:
          'Мы стараемся задавать вектор встречи заранее, но бывает и иначе. Задавать свои вопросы на другие темы, касающиеся KMM, при этом можно на любой встрече.',
        question_7:
          'Можно ли прийти с другим вопросом по Kotlin Multiplatform Mobile?',
        answer_7: 'Да!',
        question_8:
          'У меня есть вопрос по бэкенду Kotlin, получится обсудить на митапе?',
        answer_8:
          'Сейчас на встречах мы обсуждаем мобильную разработку. В будущем планируем расширять спектр вопросов для дискуссий.',
        question_9: 'Будет ли запись встречи?',
        answer_9:
          'Да, все встречи мы записываем и делимся ссылкой с участниками в течение нескольких дней.',
        question_10:
          'Можно ли прийти с общим вопросом по мобильной разработке?',
        answer_10: 'Да, конечно!',
      },
      ourClients: {
        title: 'К нам приходят из этих компаний',
      },
      footer: {
        date: 'Следующий MeetUp',
        title: 'Среда в 14:00 Мск',
        subtitle:
          'Задавайте вопросы по вашим кейсам и решайте задачи на KMM вместе с IceRock',
        button: 'Дополнительная информация',
        modalTitle: 'Запись на meetup',
      },
      form: {
        title: 'Запись на онлайн-курс',
        desc: 'Наш менеджер свяжется с вами в ближайшее время',
        name: 'Имя',
        email: 'Email',
        comment: 'Должность (необязательно)',
        textarea:
          'Впиши вопросы, на которые ты бы хотел получить ответы на мите',
      },
    },
    kmmPage: {
      kmmHeader: {
        title: 'Внедрим Kotlin Multiplatform',
        titleBigger: 'Mobile в ваш проект',
        desc: 'Экономьте время и бюджет: создавайте бизнес-логику под две платформы сразу',
        button: 'Заказать консультацию',
      },
      kmmIntegrate: {
        title: 'Когда стоит внедрить KMM',
        cards: {
          card_1:
            'У вас есть приложение или вы только начали его разрабатывать, и его будут использовать на разных платформах: Android и iOS.',
          card_2:
            'У вашего приложения с Android и iOS-версиями сложная бизнес-логика при простом UI (например, при офлайн-синхронизации).',
          card_3:
            'У вас есть Android-приложение на Kotlin, но вам срочно нужно выпустить версию на iOS c переиспользованием того, что уже сделано.',
        },
      },
      kmmForWhom: {
        subTitle:
          'Мы не только сами внедряем KMM, но еще и консультируем по переходу на нее',
        title: 'Кому это подойдет?',
        button: 'Заказать код-ревью',
        cards: {
          card_1: {
            desc: 'Ваши разработчики уже знают об этой технологии и пробовали ее внедрить',
            title: 'Им нужна наша экспертиза',
            titleExp: 'Что у нас есть:',
            exp_1: 'Наша база знаний',
            exp_2: 'Наши библиотеки',
          },
          card_2: {
            desc: 'Ваши разработчики только слышали об этой технологии, но еще не пробовали ее внедрить.',
            title: 'Им нужно наше обучение',
            titleExp: 'Что мы умеем:',
            exp_1: 'Обучать начинающих',
            exp_2: 'Обучать мидлов и сеньоров',
          },
        },
        magnitBlock: {
          title: 'Будет ли KMM полезен в вашем проекте ',
          line1:
            'Закажите бесплатное код-ревью, мы проанализируем ваш проект и предоставим <a href="/lead-magnite-kmm.pdf">результат-отчет</a>',
        },
      },
      kmmWhatsGive: {
        title: 'Что даст внедрение KMM?',
        cards: {
          card_1: 'В перспективе вы можете сэкономить до 1/3 бюджета',
          card_2: 'У разработчиков на iOS и Android будет общий контекст',
          card_3:
            'Вы ускорите разработку на 25% и в перспективе получите возможность быстрее и легче внедрить новую функцию сразу на двух платформах',
          card_4:
            'Повысится производительность разработчиков, и их можно будет параллельно подключить к другим проектам',
        },
      },
      kmmTransition: {
        title: 'Как будет происходить переход на KMM?',
        button: 'Заказать консультацию',
        cases: {
          case_1: {
            desc: 'У вас есть приложение на обеих платформах и вам нужна помощь наших разработчиков, чтобы сделать общую бизнес-логику',
            steps: [
              'Подпишем NDA',
              'Проанализируем ваш проект',
              'Составим индивидуальный план внедрения KMM',
              'Подключимся к проекту',
              'Начнем по плану переносить бизнес-логику в общий код',
            ],
            cards: {
              card_1: {
                name: 'Стоимость',
                desc: 'от 500 000 руб.',
              },
              card_2: {
                name: 'Составление плана',
                desc: 'от 2 до 5 дней',
              },
              card_3: {
                name: 'Внедрение',
                desc: 'от 2 недель до полугода',
              },
            },
          },
          case_2: {
            desc: 'Ваша команда успешно пробовала KMM и вам нужна только консультация',
            steps: [
              'Подпишем NDA',
              'Проанализируем ваш проект',
              'Составим индивидуальный план с обучающими материалами',
              'Ваша команда будет следовать плану',
              'Мы дадим вам ревью по каждому этапу внедрения.',
            ],
            cards: {
              card_1: {
                name: 'Стоимость',
                desc: 'от 100 000 руб.',
              },
              card_2: {
                name: 'Составление плана',
                desc: 'от 2 до 5 дней',
              },
            },
          },
          case_3: {
            desc: 'У вас есть приложение на Android, и вам срочно нужно выпустить версию на iOS, не останавливая разработку. А затем объединить бизнес-логику обоих приложений.',
            steps: [
              'Подпишем NDA',
              'Проанализируем ваш проект',
              'Составим индивидуальный план внедрения KMM',
              'Создадим общий код для второй платформы с заделкой на интеграцию с первой платформой',
              'Интегрируем общий код в первую платформу',
            ],
            cards: {
              card_1: {
                name: 'Стоимость',
                desc: 'от 1 000 000 руб.',
              },
              card_2: {
                name: 'Составление плана',
                desc: 'от 2 до 5 дней',
              },
              card_3: {
                name: 'Приложение с общим кодом',
                desc: 'от 1 до 6 месяцев',
              },
              card_4: {
                name: 'Интеграция в первую платформу',
                desc: 'от 2 недель до нескольких месяцев',
              },
            },
          },
        },
      },
      kmmWeDidIt: {
        title: 'Мы уже делали так раньше',
        cases: {
          case_1: {
            title: 'profi',
            subTitle: 'profi.ru',
            desc: 'Приложение для поиска специалистов из разных областей. Работает на iOS и Android. <br /><br /><strong>Что делали</strong>: мы помогали ребятам перенести бизнес-логику из приложения Android в мультиплатформу. Они подробно описали этот процесс <a href="https://habr.com/ru/post/556318/" target="_blank" rel="noreferrer">на Хабре</a> в шаге 3.',

            cards: {
              card_1: {
                name: 'Анализ и составление плана',
                desc: '2 дня на анализ',
              },
              card_2: {
                name: 'Срок',
                desc: 'Ревью в течение нескольких недель',
              },
            },
          },
          case_2: {
            title: 'Footballco',
            subTitle: 'footballco.com',
            desc: 'Сервис для просмотра футбола. Работает на iOS и Android. <br /><br /><strong>Что делали</strong>: провели аудит. Сначала ребята самостоятельно сделали общую логику и подключили ее к своим Android- и iOS-приложениям. Затем пришли к нам за экспертизой.',

            cards: {
              card_1: {
                name: 'Анализ и составление плана',
                desc: '3 дня на аудит',
              },
              card_2: {
                name: 'Стоимость',
                desc: '$1000',
              },
            },
          },
          case_3: {
            title: 'Getmega',
            subTitle: 'getmega.com',
            desc: 'Приложение для совместных игр онлайн и общения. Работает на iOS и Android.<br /><br /><strong>Что делали</strong>: создали общий код сразу с интеграцией с iOS-приложением. Общий код делали на основе версии на Android, она уже была написана на Kotlin. В результате клиент получил два приложения с общей логикой, не прерывая работу над функционалом Android приложения.',

            cards: {
              card_1: {
                name: 'Анализ и составление плана',
                desc: '3 дня на анализ',
              },
              card_2: {
                name: 'Срок',
                desc: '3 месяца разработки',
              },
              card_3: {
                name: 'Стоимость',
                desc: '> 3 млн рублей',
              },
            },
          },
        },
      },
      kmmForm: {
        title:
          'Запишитесь на бесплатную косультацию и мы расскажем как приложение изменит ваш проект',
      },
    },
    cource: {
      header: {
        advantages: 'Преимущества курса',
        aboutUS: 'О нас',
        program: 'Программа курса',
        faq: 'FAQ',
        name: 'Kotlin Multiplatform Mobile',
        title:
          'Онлайн-курс по новой технологии для программистов на Android и iOS.',
        desc: 'Научитесь работать с кросс-платформенным SDK прямо во время работы или учебы и начните зарабатывать больше с IceRock.',
        button: 'Записаться на курс',
      },
      whyKotlin: {
        title: 'Почему именно <br/><span>Kotlin</span>',
        desc_1:
          'Раньше одно и то же приложение приходилось писать отдельно под каждую операционную систему. При этом некоторые части кода были одинаковыми для разных платформ, но их все равно требовалось прописывать заново.',
        desc_2:
          'Kotlin Multiplatform Mobile (KMM) — это SDK, который позволяет создать код сразу для всех ОС и оставить возможность для изменений там, где это необходимо. Это экономит время и бюджет, поэтому программист со знанием Kotlin круче, чем просто хороший мобильный разработчик.',
        willDo: 'Этот курс подойдет',
        graduates: 'Выпускникам факультетов мобильной разработки',
        develiopers:
          'Действующим Junior и Middle программистам на iOS и Android',
        manager: 'Product-менеджерам с навыками программирования и Senior’ам',
      },
      whyIcerock: {
        title: 'Почему курс от IceRock',
        cause_1_title: 'Бесплатное обучение',
        cause_1_desc:
          'Мы сделали наш курс бесплатным, чтобы ничто не мешало вам повысить свой уровень и, возможно, стать частью нашей команды.',
        cause_2_title: 'Легко совмещать',
        cause_2_desc:
          'Все уроки уже записаны, вебинаров нет. Вы сами решаете, когда, где и сколько учиться — удобно, если работаете или получаете образование.',
        cause_3_title: 'Комфортный темп',
        cause_3_desc:
          'Вы можете закончить курс за неделю или за год — мы не требуем соблюдать дедлайны. Единственное, что влияет на сроки обучения, это ваши собственные возможности.',
        cause_4_title: 'Русский язык',
        cause_4_desc:
          'Многие программисты владеют английским, на котором написано большинство профильных курсов. Но с инструкциями на родном языке дело пойдёт ещё быстрее.',
        cause_5_title: 'Обширная библиотека',
        cause_5_desc:
          'Курс дополнен всеми необходимыми материалами, пошаговыми инструкциями с иллюстрациями и ссылками на скачивание ПО. Не нужно тратить время на поиски.',
        cause_6_title: 'Лучшим — работа сразу',
        cause_6_desc:
          'Мы не выдаем красный диплом. Вместо этого выпускников, достигших хорошего уровня в Kotlin, приглашаем к нам в команду сразу после обучения.',
      },
      aboutUs: {
        title:
          'Мы, IceRock, — эксперты в разработке приложений на Kotlin Multiplatform Mobile',
        desc_1:
          'С 2019 года делимся опытом на конференциях вроде KotlinConf и APAC и разрабатываем мультиплатформенные библиотеки на Kotlin.',
        desc_2:
          'Наши клиенты — Leroy Merlin, VEKA, Delivery Club, Marmalato, Золотое Яблоко и другие.',
        button: 'Посмотреть наши кейсы',
      },
      steps: {
        title: 'Курс состоит из 12 этапов',
        step_1: 'Знакомство с KMM',
        step_2: 'Запуск проекта',
        step_3: 'Устройство проекта',
        step_4: 'MOKO библиотеки',
        step_5: 'Делаем экран авторизации',
        step_6: 'Делаем список',
        step_7: 'Работа с сетью',
        step_8: 'Делаем профиль',
        step_9: 'Добавляем постраничную загрузку',
        step_10: 'Добавляем push уведомления',
        step_11: 'Добавляем отображение элементов на карте',
        step_12: 'Итоговый тест',
        button: 'Записаться на курс',
      },
      whatAwaits: {
        title: 'Что вас ждёт в конце курса',
        await_1_title: 'Новый уровень в разработке',
        await_1_desc:
          'Расширите технический стек и сможете выполнять больше задач быстро и качественно — а значит, перейдете на следующую профессиональную ступень.',
        await_2_title: 'Практический опыт',
        await_2_desc:
          'Во время курса поймете принципы работы и набьете руку на заданиях по программированию, чтобы уверенно применять знания в работе. ',
        await_3_title: 'Возможность претендовать на более высокий гонорар',
        await_3_desc:
          'Технология KMM экономит бизнесу время и деньги, а значит, труд специалиста с этим языком в портфеле ценится выше.',
        await_4_title: 'Новые интересные проекты',
        await_4_desc:
          'Там, где раньше вы не были уверены в своих силах, теперь будете чувствовать себя в своей стихии и сможете пополнить портфолио еще более крутыми кейсами.',
        await_5_title: 'Более эффективный менеджмент',
        await_5_desc:
          'Поймете принципы кроссплатформенной разработки, сможете упростить поддержание проекта и сделать продукт стабильнее без лишних временных и профессиональных затрат.',
      },
      footer: {
        copywriter: '© IceRockDevelopment 2021',
      },
      faq: {
        title: 'FAQ',
        question_1: 'Сколько стоит обучение?',
        answer_1: 'Курс в открытом доступе, обучение бесплатное.',
        question_2:
          'Я только окончил вуз или пока учусь и у меня еще нет практики, мне подойдет этот курс?',
        answer_2:
          'Да. Если вы не полный новичок в программировании, вам все будет понятно.',
        question_3: 'Сколько длится курс?',
        answer_3: 'Это зависит только от вас: мы не ставим сроки.',
        question_4: 'Какое ПО мне понадобится для обучения?',
        answer_4:
          'Потребуется установить актуальную версию Android Studio. Рекомендуем предварительно изучить системные требования программы.',
        question_5:
          'Это запись, или обучение будет вестись онлайн в заранее запланированное время?',
        answer_5:
          'Все уроки на платформе записаны заранее, поэтому вы можете заниматься в удобное время.',
        question_6:
          'Будет ли обратная связь от преподавателей курса по практическим вопросам?',
        answer_6:
          'Мы будем рады всем неточностям и дополнениям к этому курсу, а также ошибкам, если вдруг какая-то закралась, а с заданиями справляться мы предлагаем самостоятельно :)',
        question_7: 'Будет ли выдаваться какой-то диплом?',
        answer_7:
          'Диплома не будет, но будут знания и возможность устроиться на классную работу.',
        question_8:
          'Будет ли возможность трудоустройства после прохождения курса?',
        answer_8:
          'Лучших выпускников мы приглашаем в нашу команду. Остальные смогут включить навык программирования на Kotlin в резюме — уверены, работодатели оценят. ',
      },
      form: {
        title: 'Запись на онлайн-курс',
        desc: 'Наш менеджер свяжется с вами в ближайшее время',
        name: 'Имя',
        email: 'Email',
        comment: 'Должность (необязательно)',
      },
    },
    internship: {
      title: {
        title: 'Стажировка от <span>IceRock</span> Development',
        desc: 'Учитесь мобильной разработке на Android и iOS и начинайте работать в IT сразу после учебы',
        button: 'Подробнее',
      },
      features: {
        0: '<span>6 лет опыта</span> мобильной разработки и 60+ приложений',
        1: 'Получаем заказы со всей России и за рубежом: <span>США, Европа</span> и другие',
        2: '<span>Делимся своим опытом</span> на конференциях, в статьях на Хабре и в кейсах в нашем блоге',
      },
      cases: {
        title: 'Над чем будете работать',
        subtitle: 'Вот что наши специалисты делают для клиентов',
        link: 'Посмотреть все кейсы',
      },
      whatYouGet: {
        title: 'Что вы получите от стажировки',
        0: 'Обучение с нуля или продолжение обучения',
        1: '4-8 недель на прокачку полезного навыка',
        2: 'Задания для отработки теории: приложение по подробному ТЗ, свой проект или участие в боевом проекте — в зависимости от варианта стажировки',
        3: 'Подробный фидбек от наставника',
        4: 'Предложение работы лучшим студентам и тем, кто проходит вариант «Корпоративный университет IceRock»',
        5: 'Оплата стажировки тем, кто проходит вариант «Корпоративный университет IceRock»',
      },
      whatYouGetWithoutCorpEdu: {
        title: 'Что вы получите от стажировки',
        0: 'Обучение с нуля или продолжение обучения',
        1: '4-8 недель на прокачку полезного навыка',
        2: 'Задания для отработки теории: приложение по подробному ТЗ, свой проект или участие в боевом проекте — в зависимости от варианта стажировки',
        3: 'Подробный фидбек от наставника',
        4: 'Предложение работы лучшим студентам',
      },
      options: {
        title: '3 варианта стажировки',
        subtitle:
          'Мы открыты всем мобильным разработчикам на Android и iOS, поэтому создали несколько вариантов стажировки в зависимости от вашего уровня.',
        0: {
          title: 'Стажировка для разработчиков с нуля',
          subtitle: 'Что ждет на стажировке',
          0: 'Выдадим базовый список материалов для изучения',
          1: 'Вы будете самостоятельно разрабатывать Android-приложение «Заметки» по выданным требованиям',
          2: 'У вас будет возможность видеовстречи для более детального обсуждения фидбека и обозначения курса для дальнейшего движения',
          3: 'Вы получите ревью своей работы на нашем github.com с пометками, что стоит поправить, что почитать, как изменять',
          specialOne: {
            title: 'Оплата стажировки',
            subtitle: 'не предусмотрена, участие бесплатное',
          },
          specialTwo: {
            title: 'Трудоустройство',
            subtitle: 'да, лучшим студентам после окончания стажировки',
          },
        },
        1: {
          title: 'Преддипломная практика',
          subtitle: 'Что ждет на стажировке',
          0: 'Выдадим базовый список материалов для изучения',
          1: 'Выделим наставника из числа сотрудников компании',
          2: 'С установленной периодичностью наставник будет отвечать на вопросы и делать ревью',
          3: 'С установленной периодичностью будут проводиться видеовстречи с наставником, где можно решить вопросы голосом или расшарив друг другу экран',
          4: 'При необходимости поможем с выбором темы дипломной работы',
          5: 'Примерно обозначаем, как можно решить задачу по диплому (предложим библиотеки, архитектуры, которые можно взять и т. п.)',
          6: 'Поможем с несложным дизайном, чтобы были графические ресурсы к выполнению задачи',
          specialOne: {
            title: 'Оплата стажировки',
            subtitle: 'не предусмотрена, участие бесплатное',
          },
          specialTwo: {
            title: 'Трудоустройство ',
            subtitle: 'да, лучшим студентам после окончания стажировки',
          },
        },
        2: {
          title: 'Корпоративный университет IceRock',
          desc: 'Для тех, у кого есть база. Количество мест ограничено',
          subtitle: 'Что ждет на стажировке',
          0: 'Полная занятость',
          1: 'Обучением занимается лично технический директор IceRock: <a href="https://github.com/alex009" target="_blank">https://github.com/alex009</a>',
          2: 'Обучающий курс включает в себя:  <br/> 1. Разработка под android (базовый уровень). <br/> 2. Разработка под iOS (базовый уровень). <br/> 3. Разработка с использованием Kotlin Multiplatform Mobile. <br/> 4. Выпускная работа — реализация проекта приближенного к продакшен уровню в реальных рабочих процессах и инструментах.',
          3: 'После завершения обучения разработчик попадает в одну из команд разработки, где продолжает развиваться, работая на проектах заказчика',
          specialOne: {
            title: 'Оплата стажировки',
            subtitle: 'да, полноценная зарплата junior-разработчика',
          },
          specialTwo: {
            title: 'Трудоустройство',
            subtitle:
              'да, с первого дня стажировки официальное трудоустройство в компанию',
          },
        },
      },
      benefits: {
        title: 'Преимущества работы в IceRock',
        0: '<span>Гибкий</span> график.',
        1: '<span>Заказчики из разных стран</span>: России, США, Европы и других. ',
        2: 'Работа в следующих <span>направлениях разработки</span>: Telecom, Bookings, Payments, Healthcare, Internet of Things, M-commerce, Delivery, Social и Location-based.',
        3: 'Работа над <span>полезными продуктами</span> с использованием разнообразных технологий. ',
        4: '<span>Индивидуальный план развития</span> и 2 оплачиваемых часа в неделю в рабочее время на обучение. А также возможность обсудить тему и поделиться своими знаниями на внутренних митапах.',
        5: '<span>Свобода для творчества и индивидуальное признание.</span> Мы ценим в сотрудниках креативность и находчивость. Любим, когда специалист не только следует инструкции, но и предлагает свои идеи. Каждый год подводим итоги и награждаем лучших. ',
        6: '<span>Теплый и дружный коллектив</span>, с которым будете играть во время отдыха в «Мафию», «Каркассон» или даже в «Ужас Аркхэма». ',
        7: '<span>Просторный офис с современной техникой</span> в центре Новосибирска или удаленная работа.',
      },
      form: {
        title: 'На какую стажировку хотите попасть?',
        name: 'Ваше имя',
        email: 'Ваш e-mail',
        chooseOption: 'Выберите стажировку',
        button: 'Хочу стажироваться в IceRock!',
        textarea: 'Расскажите про свой опыт в программировании',
      },
    },
    iot: {
      title: {
        title:
          'Разработка iOS и Android приложений для управления автомобилем, умным домом и бытовой электроникой',
        desc: 'Сделайте работу с продуктом удобнее, современнее и зарабатывайте больше',
      },
      solutions: {
        title: 'Наши решения для реального бизнеса',
        tab_0: {
          title: 'Авто',
          desc:
            '<p>«Элемент» — умная система для удаленного управления автомобилем. Она помогает следить за его состоянием, экономить на КАСКО и бензине, а еще собирает статистику о манере вождения.</p>' +
            '<p>«Элемент» состоит из устройства, устанавливаемого в автомобиль и приложения, которое позволяет владельцу следить за состоянием машины.</p>' +
            '<p>Приложение создано для «Лаборатории Умного Вождения». Работает на iOS и Android.</p>',
          features: {
            0: 'Автозапуск',
            1: 'Управление светом',
            2: 'Управление предпусковым подогревателем',
            3: 'Открытие/закрытие багажника',
            4: 'Просмотр статистики о расходе топлива',
          },
        },
        tab_1: {
          title: 'Умный дом',
          desc:
            '<p>«НЕВОТОН АВТОМАТИКА» — мобильное приложение для удаленного управления устройствами «умного дома».</p>' +
            '<p>Пользователь может устанавливать время работы приборов, настраивать температуру, влажность, задавать сценарий функционирования — одновременное включение приборов отопления, освещения и других. Сценарий запускается одним нажатием кнопки.</p>' +
            '<p>Создано для компании «НЕВОТОН». Работает на iOS и Android.</p>',
          features: {
            0: 'Управление освещением, отоплением, оборудованием сауны и бассейна',
            1: 'Удаленный запуск',
            2: 'Настройка сценариев запуска',
            3: 'Выбор параметров работы приборов',
            4: 'Контроль за состоянием по данным с датчиков и камер',
          },
        },
        tab_2: {
          title: 'Бытовая электроника',
          desc:
            '<p>«Мой холодильник» — новое решение для оплаты продуктов из вендинговых аппаратов.</p>' +
            '<p>Чтобы совершить покупку в коммерческом холодильнике компании-заказчика, не нужно носить с собой наличные и вводить номер позиции на клавиатуре устройства. В приложении пользователь сканирует QR-код с корпуса холодильника, получает список продуктов в нем и покупает нужные по банковской карте.</p>' +
            '<p>Создано для компании «Белая Сфера». Работает на iOS и Android.</p>',
          features: {
            0: 'Сканирование QR-кода для идентификации устройства',
            1: 'Автозагрузка списка продуктов, взятых из холодильника',
            2: 'Оплата привязанной банковской картой',
            3: 'История покупок',
            4: 'Электронный чек',
          },
        },
      },
      principles: {
        title: 'Наши принципы',
        desc: 'У нас в IceRock есть принципы, которых мы придерживаемся при разработке любого приложения. Они помогают нам создавать качественные продукты, которые на самом деле закрывают потребности клиентов — даже если они не вписываются в шаблоны.',
        0: {
          title: 'Используем безопасные протоколы',
          desc: 'передачи данных и полностью тестируем приложение, чтобы защитить клиентов от злоумышленников, а вас — от пятен на репутации.',
        },
        1: {
          title: 'Прописываем ответственность за срыв сроков',
          desc: 'в договоре, чтобы вы могли уверенно планировать внутренние и внешние процессы по запуску продукта.',
        },
        2: {
          title: 'Используем',
          desc: 'Kotlin Multiplatform Mobile, чтобы сэкономить ваше время и деньги на правках и обновлениях приложения.',
        },
        3: {
          title: 'Делаем быстрый и выгодный прототип',
          desc: 'со всеми ключевыми функциями в сжатые сроки. Подробнее — <a href="https://prototyping.icerockdev.com/" target="_blank">здесь.</a>',
        },
        4: {
          title: 'Изучаем конкурентов и потребности целевой аудитории',
          desc: 'чтобы ваше приложение выделялось на фоне других.',
        },
        5: {
          title: 'Пишем бизнес-логику под iOS и Android сразу',
          desc: 'не придется долго ждать разработки и платить за каждую платформу отдельно.',
        },
        6: {
          title: 'Опираемся на опыт',
          desc: 'в сегменте IoT — мы готовы подтвердить свой опыт и гарантировать ответственность и высокий уровень реализации приложения.',
        },
      },
      whenOrder: {
        title: 'Когда пора обратиться за приложением',
        cards: {
          0: 'На этапе идеи — заказать MVP и проверить ее',
          1: 'Когда решение о создании принято — осталось реализовать',
          2: 'Если приложение есть, но требует обновления и доработки',
        },
        features: {
          0: { bold: '4 месяца', text: 'с нуля до запуска' },
          1: { bold: 'от 1,5 млн руб.', text: 'базовая стоимость' },
          2: { bold: '2-4 месяца', text: 'гарантии' },
        },
      },
      form: {
        title: 'Оставить заявку',
        name: 'Имя',
        agree: {
          text: 'Нажимая кнопку «ОТПРАВИТЬ», вы даете согласие на обработку своих',
          link: 'персональных данных',
        },
        button: 'Отправить',
      },
      whyUs: {
        title: 'Почему IceRock',
        desc: 'Мы, IceRock, — эксперты в разработке IoT-приложений на Kotlin Multiplatform Mobile. KMM помогает нам решать самые разные задачи: за шесть лет работы на нашем счету более 60 крупных реализованных проектов.',
        text: 'Наша команда с 2017 года занимает верхние строчки рейтингов разработчиков мобильных приложений, а в 2020 году IceRock вошла в ТОП подрядчиков для B2B сегмента в России.',
      },
      faq: {
        title: 'FAQ',
        titleMobile: 'Частые вопросы',
        0: {
          title: 'Сможете добавить новые функции в наше приложение?',
          desc: 'Мы всегда ориентируемся на конкретный случай и предлагаем решение, оптимальное именно для него. Предложим доработать ваше или написать новое приложение в зависимости от того, что будет лучше для вас.',
        },
        1: {
          title: 'У вас есть поддержка приложения?',
          desc: 'Есть, и мы самостоятельно отслеживаем отзывы пользователей и предлагаем обновления и улучшения.',
        },
        2: {
          title: 'От чего зависит срок и на что распространяется гарантия?',
          desc: 'Сроки гарантии зависят от характера и объема работы, все это обсуждаем заранее и прописываем в договоре. По гарантии будем бесплатно исправлять все ошибки, которые могут появиться в процессе использования приложения.',
        },
        3: {
          title: 'Как происходит интеграция приложения с оборудованием?',
          desc: 'Вы предоставляете нам API, а также оборудование или продукт, для которого заказываете приложение, а мы тестируем его функционал и разрабатываем программу конкретно под него.',
        },
        4: {
          title: 'Можно ли связать приложение с другими службами?',
          desc: 'Да, мы можем настроить, например, возможность уведомления охраны или полиции о попытке взлома. Для этого нам понадобятся протоколы передачи данных и подобные внешние решения.',
        },
        5: {
          title:
            'Будет ли приложение автоматически адаптироваться к новым продуктам?',
          desc: 'Наш код очень гибкий, но при появлении абсолютно нового продукта даже его придется дорабатывать по отдельной статье расходов.',
        },
        6: {
          title: 'Участвуете ли вы в тендерах?',
          desc: 'Да.',
        },
      },
    },
    finance: {
      header: {
        title:
          'Разработка iOS- и Android-приложений для внутренних задач банка и инвестиций',
        desc: 'Помогаем банкам оптимизировать работу и обслуживать физических и юридических лиц онлайн',
        phone: '+74951097329',
        email: 'mobiledev@icerockdev.com',
        button: 'Отправить заявку',
      },
      solutions: {
        title: 'Наши решения для реального бизнеса',
        desc: 'Мы уже реализовывали проекты в банковской сфере под разные задачи',
        cards: [
          { title: 'Приложения для управления инвестициями' },
          { title: 'Приложения для продуктов банка' },
        ],
        linkName: 'Детальная информация',
        tab_0: {
          title: 'Upstox',
          desc: 'Upstox — мобильное приложение для инвестиций и трейдинга.  Работает на iOS и Android.',
          features: [
            { title: 'Динамические информационные секции' },
            { title: 'Сторис' },
            { title: 'Статистика в реальном времени' },
            { title: 'Биржевые рейтинги' },
            { title: 'Интегрированный и обучающий контент' },
            { title: 'Котировки' },
          ],
        },
        tab_1: {
          title: 'SBI-банк',
          desc: 'SBI — банк, ориентированный на семейных клиентов. Работает на Android и iOS.',
          features: [
            { title: 'Доступ к счетам с мобильных устрйств ' },
            { title: 'Кешбек' },
            { title: 'Установка лимитов на траты' },
            { title: 'Система геймификации ' },
            { title: 'Сложная архитектура* ' },
          ],
          note: '*Каждая функция делится на бизнес- и UI-модули',
        },
        tab_2: {
          title: '«ВТБ Кворум»',
          desc: '«ВТБ Кворум» — приложение для проведения голосования на мероприятиях ВТБ. Работает на iPad, написано на Swift.',
          features: [
            { title: 'Проведение устного голосования на мероприятиях' },
            { title: 'Идентификация пользоваеля по базе' },
            { title: 'NFC-метки' },
            { title: 'Технология распознования голоса' },
            { title: 'Чтение вопроса с помощью Siri' },
          ],
        },
        tab_3: {
          title: 'Register MFO',
          desc: 'Registry MFO — реестр микрокредитных организаций. Работает на Android и iOS.',
          features: [
            { title: 'Единый дизайн для двух платформ' },
            { title: 'Реестр данных' },
            { title: 'Единый исходный код на два приложения' },
            { title: 'Быстрый поиск*' },
          ],
          note: '*Нужной микрокредитной организации и отслеживание ее статуса',
        },
        tab_4: {
          title: '«Кассир»',
          desc: '«Кассир» — приложение, которое генерирует уникальные идентификаторы для быстрых денежных переводов. Работает на Android и iOS.',
          features: [
            { title: 'Денежные переводы' },
            { title: 'Шифрование данных' },
            { title: 'Интеграция с Android SDK' },
            { title: 'Статистика операций' },
          ],
        },
      },
      developBank: {
        title:
          'Разрабатываем мобильные приложения для банков, исходя из поставленной задачи',
        steps: [
          'Выйти на рынок с новым продуктом',
          'Обновить актуальное приложение',
          'Создать свое приложение вместо шаблонного решения',
          'Повысить лояльность и уменьшить отток клиентов',
          'Создать конкурентное приемущество',
          'Сделать банк-клиент для юридических лиц',
        ],
      },
      developApp: {
        title: 'Создадим приложение с нуля',
        desc: 'Поможем сделать качественный, удобный и понятный продукт, который вовлечет аудиторию',
        steps: [
          {
            title: 'Подготовка',
            list: [
              'Проанализируем приложения конкурентов',
              'Сделаем анализ предыдущего приложения',
              'Сделаем CustDev и нарисуем CJM',
              'Проведем аудит и разработаем требования к приложению',
            ],
          },
          {
            title: 'Разработка',
            list: [
              'Подробно обсудим ТЗ',
              'Подготовим прототипы',
              'Назначим контрольные точки',
              'Разработаем дизайн в фирменном стиле',
              'Сформируем бэклог',
              'Обсудим результат каждого этапа',
            ],
          },
          {
            title: 'Тестирование',
            list: [
              'Протестируем прототип на реальном закрытом сегменте  аудитории',
              'Если необходимо — доработаем приложение',
              'Составим план дальнейшего развития',
            ],
          },
        ],
        magnitBlock: {
          title:
            'Хотите узнать, сколько стоит разработать банковское приложение и на чем можно сэкономить?',
          line1:
            'Оставьте свой e-mail, и мы пришлем pdf-документ, в который упаковали весь наш опыт.',
          line2:
            'Покажем “кухню” заказной разработки в финтехе и раскроем суммы, которые платят за каждый этап разработки. Также вы узнаете, на чем можно сэкономить.',
        },
      },
      finalizeApp: {
        title: 'Доработаем ваше приложение',
        desc: 'Оптимизируем работу существующего приложения',
        steps: [
          'Сделаем приложение более удобным и добавим новые функции.',
          'Проанализируем его работу и снизим затраты на поддержку и развитие.',
          'Сделаем дополнительные интеграции с существующими программными решениями.',
        ],
      },
      mvp: {
        title: 'Сделаем прототипирование MVP для банков',
        desc: 'Создадим продукт, обладающий минимальными, но достаточными для проверки гипотезы функциями. По результатам можно принять решение о дальнейшей разработке.',
        offer: {
          title: 'Наше предложение',
          desc: 'Быстрый старт с минимальными денежными затратами',
          price: { title: 'Цена:', content: 'от 1 000 000 ₽' },
          time: { title: 'Срок:', content: 'от 3 месяцев' },
        },
      },
      promises: {
        card_1: {
          title: '<span>Настроим</span> необходимые функции',
          primaryList: [
            'Переводы и платежи',
            'Управление инвестициями',
            'Управление счетами и финансами',
          ],
          list: [
            'Открытие новых продуктов без посещения офиса',
            'Формирование и подписание платежных поручений',
            'Уплату налогов, шртафов, оплата услуг ЖКХ, сотовой связи',
          ],
        },
        card_2: {
          title: '<span>Сделаем</span> безопасный продукт',
          desc: 'В работе мы используем современные подходы безопасности:',
          primaryList: [
            'HTTPS',
            'Шифрование данных',
            'Role-based access control',
          ],
          list: [
            'Двухфакторную аутентификацию',
            'Систему распознавания лиц Face ID',
            'Сканер отпечатков пальцев Touch ID',
            'Проведение внешнего аудита специалиста по безопасности данных',
            'Хранение персональных данных в соответствии с требованиями законодательства',
          ],
        },
      },
      reviewsSlider: {
        title: 'Почитайте, что клиенты пишут о нашей работе',
        button: 'Все отзывы',
      },
      kmm: {
        title: 'Ускорим разработку благодаря экспертизе в Kotlin Multiplatform',
        desc: 'В работе используем Kotlin Multiplatform, благодаря чему бизнес-логика пишется под две платформы сразу. Это сокращает время разработки и экономит ваш бюджет.',
        cards: [
          'Оптимизируем разработку с помощью общего кода для IOS и Android',
          'Сокращаем денежные затраты и время разработки, запускам продукт в срок',
        ],
        developTime: 'Стандартный срок разработки: <span>~4 месяца</span>',
      },
      guarantees: {
        title: 'Наши гарантии',
        cards: [
          'Бесплатное исправление ошибок*',
          'Соответствие техническому заданию',
        ],
        desc: '*На протяжении 3-6 месяцев, в зависимости от проекта',
      },
      stayWithYou: {
        title: '<span>Остаемся с вами</span> после запуска проекта',
        desc: 'Мы можем долгосрочно участвовать в разработке и поддержке продукта',
        list: [
          'Вместе сделаем Roadmap развития продукта',
          'Составим приоритет по фичам',
          'Продумаем карту релизов',
          'Запустим разработку',
          'Реализуем новые фичи по мере роста проекта',
        ],
      },
      form: {
        title: 'Хочу такое приложение',
        name: 'Имя',
        agree: {
          text: 'Нажимая кнопку «ОТПРАВИТЬ», вы даете согласие на обработку своих',
          link: 'персональных данных',
        },
        button: 'Отправить',
      },
      faq: {
        title: 'FAQ',
        titleMobile: 'Частые вопросы',
        0: {
          title:
            'Можно ли настроить интеграцию с существующими программными комплексами?',
          desc: 'Да, мы настроим интеграцию с другими продуктами с помощью REST, SOAP или WebServices.',
        },
        1: {
          title: 'Сколько стоит разработка приложения?',
          desc: 'Цена зависит от сложности и объема проекта. Разработка MVP — от 1 млн рублей, небольшого приложения — от 2 млн рублей, большого банковского приложения — от 5 млн рублей. Стоимость указана за разработку приложения под одну платформу.',
        },
        2: {
          title:
            'Сможете подготовить прототипы и примеры дизайна нескольких экранов?',
          desc: 'Да, после обсуждения технического задания мы подготовим прототипы и примеры дизайна в вашем фирменном стиле.',
        },
        3: {
          title: 'Участвуете ли вы в тендерах?',
          desc: 'Да, мы участвуем в тендерах. Заполните форму обратной связи ниже, чтобы отправить нам приглашение на участие в тендере.',
        },
        4: {
          title: 'Можете ли доработать бэкенд?',
          desc: 'Да, если он на Kotlin.',
        },
      },
    },
    cdto: {
      header: {
        title: 'Готовая команда специалистов по цифровизации вашего бизнеса',
        desc: 'Поможем автоматизировать ручной труд и сделать производство эффективнее и экономнее',
        phone: '+74951097329',
        email: 'mobiledev@icerockdev.com',
        button: 'Отправить заявку',
      },
      pros: {
        title: 'Цифровизация позволит',
        list: [
          'Cнизить зависимость производства от человеческого фактора',
          'Cнизить себестоимость производства за счет использования больших данных',
          'Cнизить количество циклов «доводки» продукции, снизить расходы на ОТК',
        ],
      },
      solutions: {
        title: 'Опыт работы',
        part_0: {
          title: 'Мы работали в следующих направлениях',
          list: [
            {
              title: 'Производство',
              features: [
                'Aвтоматизация производственных процессов',
                'Автоматизация отчетности',
                'Автоматизация подачи и обработки заявок',
              ],
            },
            {
              title: 'HR tech',
              features: [
                'Обучение новых сотрудников производственному процессу',
                'Автоматическое тестирование сотрудников',
              ],
            },
            {
              title: 'Fintech',
              features: [
                'Объединение банковских технологий',
                'Подключение банков-партнеров к магазинам',
                'Обеспечение безопасности операций',
              ],
            },
          ],
        },
        part_1: {
          title: 'Реализовали проекты для крупных компаний',
          list: [
            '/images/directions/iiko.svg',
            '/images/directions/iiko.svg',
            '/images/directions/iiko.svg',
          ],
        },
        desc: 'Мы работали в следующих направлениях',
      },
      ourwork: {
        linkName: 'Подробная информация',
        tab_0: {
          title: '«Цифровой советчик»',
          desc_0:
            'С помощью физико-химических и ML-моделей он в режиме реального времени дает рекомендации оператору: что нужно добавить в сплав для достижения оптимального результата. ',
          desc_1:
            'Позволяет предприятию экономить на количестве ферросплавов и количестве производственных циклов.',
          features: [
            { title: 'Apache Kafka' },
            { title: 'Kotlin' },
            { title: 'REST API' },
            { title: 'Интеграция по принципу адаптеров' },
            { title: 'Java' },
          ],
        },
        tab_1: {
          title: 'Leroy Merlin',
          desc_0:
            'Мобильное приложение с административной панелью для обучения сотрудников сети магазинов. В приложении сотрудники проходят тесты, которые помогают оценить, насколько удалось разобраться в рабочих процессах.',
          desc_1:
            'За тем, как сотрудники проходят тестирование, следят их наставники и руководители. Они проверяют тесты, создают новые, отслеживают статистику с помощью административной панели. Так им удается оценить, насколько персонал разобрался в рабочем процессе.',
          features: [
            { title: 'RxJava2' },
            { title: 'Retrofit + OkHttp' },
            { title: 'Java' },
            { title: 'Dagger 2' },
            { title: 'Fabric, FCM' },
          ],
        },
        tab_2: {
          title: '«Кассир»',
          desc_0:
            'Мобильное приложение, которое генерирует уникальные идентификаторы для быстрых денежных переводов. Продавец указывает в приложении название денежной операции и сумму.',
          desc_1:
            'Приложение генерирует уникальный идентификатор операции, затем покупатель оплачивает по нему покупку. После оплаты у продавца отображается статус платежа.',
          features: [
            { title: 'Kotlin Multiplatform Mobile' },
            { title: 'SQLDelight' },
          ],
        },
        tab_3: {
          title: '«ВТБ Кворум»',
          desc_0:
            'Мобильное приложение для проведения голосования на кворуме ВТБ. Доработали запрос, который идентифицировал пользователя по базе.',
          desc_1:
            'Сделали голосовой модуль с помощью нативного iOS-фреймворка Speech, через который работает голосовой помощник Siri.',
          features: [
            { title: 'Нативный iOS-фреймворк Speech' },
            { title: 'iOS на Swift' },
          ],
        },
      },
      howWeWork: {
        title: 'Как работаем',
        steps: [
          'Вводный созвон, обсуждение потребностей и возможностей ',
          'Подписание договора и соглашения о неразглашении',
          'Подготовка аналитики, проектирование архитекутры решения',
          'Организация работы над проектом: инфраструктура, issue-трекер, спринты, оценка',
          'Разработка',
          'Отгрузка очередного спринта',
        ],
      },
      ourStack: {
        title: 'Наши специалисты и стек технологий',
        cards: [
          {
            iconUrl: ['/images/directions/cdto/analytics.svg'],
            title: 'Аналитика',
            specialists: '3 специалиста ',
            body: 'Vision, Use Case, User Story, UML 2.0, BPMN 2.0, DFD, ER-диаграммы, API specification',
          },
          {
            iconUrl: ['/images/directions/cdto/design.svg'],
            title: 'Дизайн и UI/UX',
            specialists: '5 специалистов',
            body: 'Figma, Sketch, Adobe XD, фреймворки CJM, JTBD, Persona, гайдлайны платформ: Material Design и HIG',
          },
          {
            iconUrl: ['/images/directions/cdto/prototype.svg'],
            title: 'Прототипирование',
            specialists: '2 специалистa',
            body: 'ProtoPie, Figma, Axure RP, фреймворки CJM, JTBD, Persona',
          },
          {
            iconUrl: ['/images/directions/cdto/frontend.svg'],
            title: 'Frontend',
            specialists: '7 специалистов',
            body: 'React.js и Vue.js',
          },
          {
            iconUrl: ['/images/directions/cdto/backend.svg'],
            title: 'Backend',
            specialists: '12 специалистов',
            body: 'Kotlin, PHP, Java, Spring Boot, Ktor + Exposed + свои opensource-библиотеки: Yii 2, PostgreSQL, MongoDB, Redis',
          },
          {
            iconUrl: [
              '/images/directions/cdto/ios.svg',
              '/images/directions/cdto/android.svg',
            ],
            title: 'Мобильная разработка',
            specialists: '29 специалистов',
            body: 'Java, Kotlin, Android OS 5.0+, Kotlin Native и Kotlin Multiplatform Mobile, Objective-C, Swift, iOS 12+',
          },
        ],
      },
      price: {
        title: 'Как рассчитывается стоимость',
        desc: 'Стоимость складывается из часового рейта специалиста, количества специалистов и планируемой нагрузки',
        offer: {
          title: 'Наше предложение',
          desc: 'Расскажите нам о своем проекте, и мы обсудим стоимость. Это бесплатно.',
        },
      },
      reviewsSlider: {
        title: 'Почитайте, что клиенты пишут о нашей работе',
        button: 'Все отзывы',
      },
      form: {
        title: 'Хочу такое приложение',
        name: 'Имя',
        agree: {
          text: 'Нажимая кнопку «ОТПРАВИТЬ», вы даете согласие на обработку своих',
          link: 'персональных данных',
        },
        button: 'Отправить',
      },
      faq: {
        title: 'FAQ',
        titleMobile: 'Частые вопросы',
        0: {
          title:
            'Сможете настроить интеграцию с существующими программными комплексами?',
          desc: 'Да, мы настроим интеграцию с другими продуктами с помощью REST, SOAP или WebServices.',
        },
        1: {
          title: 'Можете доработать бэкенд?',
          desc: 'Да, если он на Kotlin, Java или PHP.',
        },
        2: {
          title: 'Как быстро сможете подключиться?',
          desc: 'От двух недель, если наши разработчики не заняты в крупном проекте. Аналитика, как правило, можем подключить быстрее.',
        },
        3: {
          title: 'Сколько стоит разработка приложения?',
          desc: 'Стоимость складывается из часового рейта специалиста, количества специалистов и планируемой нагрузки. Напишите нам, чтобы обсудить проект.',
        },
        4: {
          title:
            'Сможете разработать приложения, дизайн, протестировать проект?',
          desc: 'Да, мы можем выполнить весь цикл разработки. В нашей команде есть разработчики приложений для iOS и Android, QA-специалисты, дизайнеры, UI/UX-специалисты.',
        },
        5: {
          title: 'Какие гарантии даете?',
          desc: 'Гарантия прописана в договоре и может быть применена в формате разработки с фиксированной ценой. Если мы выделяем разработчика или команду для подключения к удаленной команде, то работаем по формату Time & Material, а в этом случае, как правило, гарантия не применяется.',
        },
      },
    },
    presentation: {
      modal_title: 'Отправим презентацию кейса на ваш email',
      get: 'Скачать презентацию',
      success_title: 'Спасибо за обращение!',
      success_text: 'Мы отправим вам презентацию в ближайшее время',
      succes_ok: 'Ок',
    },
  },
  form: {
    email: 'Email',
    phone: 'Телефон',
    name: 'Имя',
    field_required: 'Заполните это поле',
    fill_all_fields: 'Заполните все необходимые поля',
  },
  form_vacancy: {
    name: 'Имя*',
    email: 'Email*',
  },
  contacts: {
    title: 'Наши контакты',
    button: 'Свяжитесь с нами',
    moscow: 'Москва',
    novosibirsk: 'Новосибирск',
    phone_moscow: 'Телефон (Московский)',
    email: 'Email',
    phone: 'Телефон',
    name: 'Имя',
    hr_name: 'HR',
    fill_this_field: 'Заполните это поле',
    send_request: 'Отправить',
    resume: 'Добавить резюме',
    moscow_address: 'Страстной бульвар, 12, стр. 1',
    novosibirsk_address: 'Советская 23, стр. 301',
  },
  reviews: {
    title: 'Отзывы',
    quality: 'Качество',
    schedule: 'Точность',
    cost: 'Оценка',
    refer: 'Рекомендовал бы',
    read_full: 'Полный отзыв',
    watch_case: 'Смотреть кейс',
  },
  terms: {
    text: 'Мы используем файлы cookie, разработанные нашими специалистами и третьими лицами для анализа событий на нашем веб‑сайте, что позволяет нам улучшать взаимодействие с пользователями и обслуживание.<br />Продолжая просмотр страниц нашего сайта, вы принимаете условия его использования.<br />Более подробные сведения смотрите в нашей ',
    text_short: 'Мы используем cookies',
    link_text_more: 'Что это значит?',
    link_text_short: 'Политике конфиденциальности',
    link_text: 'Политике конфиденциальности',
    link_url: '/terms.pdf',
    button: 'OK',
  },
  order: {
    title: 'Давайте вместе создавать успешные бизнес-истории',
    subtitle: 'Вы ставите задачи. Мы находим решения',
    header:
      'Запишитесь на бесплатную консультацию. И мы расскажем, как приложение изменит ваш проект.',
    subscribe: 'Подписаться на рассылку',
    name: 'Имя',
    company: 'Компания',
    email: 'Email',
    phone: 'Телефон',
    textarea_placeholder:
      'Расскажите о своём проекте. Опишите его идею. Какие задачи он поможет решить клиентам?',
    send_request: 'Отправить',
    additional_questions: 'Дополнительные вопросы',
    additional_subtitle:
      'Ответьте на дополнительные вопросы, чтобы мы точнее поняли идею вашего проекта. <br> Если у Вас пока нет информации, просто пропустите этот шаг.',
    feature_list:
      'Перечислите основные функциональные возможности вашего приложения',
    feature_placeholder:
      'Опишите, чем ваше приложение будет отличается от существующих и чем будет привлекать пользователей',
    materials: 'У вас уже есть наработки по проекту?',
    technical_task: 'Техническое задание',
    technical_task_link: 'Вставьте ссылку на ваше техническое задание',
    technology_stack: 'Технологический стек',
    technology_stack_link: 'Вставьте ссылку на ваше технологический стек',
    prototypes: 'Прототип',
    prototypes_link: 'Вставьте ссылку на существующий прототип',
    design: 'Дизайн',
    design_link: 'Вставьте ссылку на существующий дизайн',
    characteristics: 'Основные технические характеристики приложения',
    budget_title: 'Бюджет',
    budget_1: 'До 500 000',
    budget_2: 'От 500 000 до 1 000 000',
    budget_3: 'От 1 000 000 до 1 500 000',
    budget_4: 'От 1 500 000',
    success_title: 'Ваш запрос успешно отправлен',
    success_text: 'Наш сотрудник скоро свяжется с вами, чтобы обсудить проект',
    ok: 'OK',
    document: 'Добавить документ',
    maxSize:
      'Превышен максимальный объем файлов. Попробуйте прикрепить ссылку на документ выше',
    maxLength:
      'Превышенно максимальное колличество файлов. Попробуйте прикрепить ссылку на документ выше',
    button: 'Далее',
  },
  errors: {
    fill_this_field: 'Пожалуйста, заполните это поле',
    error_cant_upload: 'Пожалуйста, заполните это поле',
    error_too_big: 'Файл должен быть меньше 20 Мб',
    error_incorrect_mime: 'Добавьте изображение или документ',
    incorrect_captcha: 'Проверьте ещё раз',
    oops_error: 'Упс! Что-то пошло не так.',
    gatsby_endpoint_error: 'Укажите GATSBY_MAIL_ENDPOINT в файле -  .env!',
  },
  case: {
    task: 'Задача',
    solution: 'Решение',
    process: 'Процесс разработки',
    hardest_part: 'Что было самым сложным',
    how_we_solved: 'Как мы решили эту задачу',
    conclusions: 'Какие сделали выводы ',
    technology: 'Технологический стек',
    mobile: 'Мобильное приложение',
    customer_feedback: 'Прямая речь заказчика',
    results: 'Результат',
    discuss: 'Давайте обсудим ваш проект!',
    we_will_tell:
      'Это бесплатно. Мы расскажем, как приложение решит конкретные бизнес-задачи.',
    leave_a_request: 'Оставить заявку на консультацию',
    similar_projects: 'Похожие проекты',
  },
  in_develop: {
    process: 'Кейс находится в процессе описания',
    come_back: 'Приходите через пару дней',
  },
  speeches: {
    title: 'Выступления',
    description: 'Выступления наших экспертов',
    prev: 'Прошедшие выступления',
    next: 'Предстоящие выступления',
    process: 'Выступления находятся в процессе описания',
    come_back: 'Приходите через пару дней',
  },
};
