import React, { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

interface IProps {
  children: string;
}

const PageTitle: FC<IProps> = ({ children }) => {
  const { messages } = useIntl();

  const title = useMemo(
    () => (children && messages[children]) || children || '',
    [children, messages]
  );

  return (
    <Helmet>
      <title>
        {title ? `${title} — ` : ''}
        IceRock
      </title>
    </Helmet>
  );
};

export { PageTitle };
