import React, { FC, ReactNode, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { useLocation } from '@reach/router';
import styles from './styles.module.scss';
import { Trans } from '../../i18n';
import { PageTitle } from '../../i18n/PageTitle';
import { useIsMenuCollapsed } from '../../constants/hooks';
import { TermsModal } from '../../components/main/TermsModal';
import { MainLayoutMenu } from '../../components/main/MainLayoutMenu';
import { setUtmCookie } from '../../utils/setCookies';

interface IProps {
  locale: string;
  title: string;
  children?: ReactNode;
}

const MainLayout: FC<IProps> = ({ title = '', children, locale }) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useIsMenuCollapsed(location);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  useEffect(() => {
    setUtmCookie(30);
  }, []);

  return (
    <>
      <Trans locale={locale}>
        <PageTitle>{title}</PageTitle>
        <div className={styles.wrap}>
          <MainLayoutMenu
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
          />

          <div
            className={classnames('content-window', styles.content, {
              [styles.collapsed]: collapsed,
              'main-menu-collapsed': collapsed,
            })}
          >
            <TermsModal />
            {children}
          </div>
        </div>
      </Trans>
    </>
  );
};

export { MainLayout };
