// eslint-disable-next-line @typescript-eslint/no-var-requires
const ru = require('date-fns/locale/ru');

module.exports = {
  ru: {
    path: 'ru',
    locale: 'Russian',
    default: true,
    date: ru.default,
  },
};
